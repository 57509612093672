/* App.css */
/*@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
*/
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Gupter:wght@400;500;700&family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: "Gupter", serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  background-color: #faf0e6;
}

.divider {
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #eed9c4;
}

.divider2 {
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 7%;
  margin-right: 7%;
  background-color: #eed9c4;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  align-items: center;
  justify-self: center;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

nav {
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 60px;
  width: 60px;
  height: 50px;
}

nav ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
  margin-right: 65vw;
}

nav ul li {
  cursor: pointer;
  font-size: 16px;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  position: relative;
  padding-bottom: 2px;
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transition: width 0.3s ease;
}

nav ul li a:hover::after {
  width: 100%;
}

.header-right {
  display: flex;
  align-items: right;
}

.see-my-work {
  margin-right: 20px;
}

.social-icons {
  display: flex;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: #e0e0e0;
}

.social-icon svg {
  width: 20px;
  height: 20px;
  color: #333;
}

.linkedin:hover svg,
.github:hover svg {
  color: #333;
}

.see-my-work {
  background-color: #f5f5f5;
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 16px;
  font-size: 12px;
  cursor: pointer;
}

.profile {
  display: flex;
  gap: 120px;
}

.left-column {
  flex: 1;
  max-width: 300px;
  margin-left: 160px;
}

.right-column {
  flex: 2;
  max-width: 480px;
}

.profile-image {
  width: 60%;
  border-radius: 20px;
}

.left-column h1 {
  font-size: 42px;
  margin-bottom: 10px;
}

.left-column p {
  font-size: 16px;
  color: #666;
}

.right-column h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  font-size: 12px;
}

.talk-with-me, .see-my-work {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.talk-with-me {
  background-color: #1d1d1d;
  color: #fff;
  border: none;
}

.see-my-work {
  background-color: #faf0e6;
  border: 1px solid #acacac;
}

@media (max-width: 768px) {
  .profile {
    flex-direction: column;
  }

  .left-column, .right-column {
    max-width: 100%;
  }
}

.work-experience, .awards {
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.work-experience h2 {
  font-size: 12px;
  font-weight: 400;
}

.experience-item, .award-item {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
}

.experience-item img, .award-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-logo {
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 50%;
}

.experience-item h3, .award-info h3 {
  font-size: 18px;
  margin: 0;
}

.experience-item p, .award-info p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0 0;
}

.experience-item span, .award-item span {
  margin-left: auto;
  font-size: 14px;
  color: #666;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Projects Page */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.profile-image {
  animation: fadeIn 1s ease-in-out;
}

h1, h2, p, .cta-buttons, .divider, .experience-header, .experience-item, .profile-image, .project-card{
  animation: fadeIn 1s ease-in-out;
}

.projects {
  width: 84%;
  margin: 0 auto;
  padding: 20px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 48px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.project-card {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.card-content {
  padding: 20px;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: scale-down;
  border-radius: 8px 8px 0 0;
}

.name-and-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.name-and-link h3 {
  margin: 0;
  font-size: 1.2rem;
}

.name-and-link .name-and-link-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.name-and-link .name-and-link-icons a {
  display: inline-block;
  align-items: center;
}

.view-link {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.view-link svg {
  width: 20px;
  height: 20px;
}

.hover-line,
.hover-line-bottom {
  position: absolute;
  background-color: #000;
  transition: width 0.3s ease, height 0.3s ease;
}

.hover-line {
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
}

.hover-line-bottom {
  bottom: 0;
  right: 0;
  width: 2px;
  height: 0;
}

.project-card:hover .hover-line {
  width: 100%;
}

.project-card:hover .hover-line-bottom {
  height: 100%;
}